<template>
    <modal ref="modalEliminarTarea" titulo="Eliminar tarea">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col text-center">
                <p> ¿Está seguro que quiere eliminar esta tarea? </p>
            </div>
        </div>
        <template slot="end-buttons">
            <button type="button" class="btn ml-1 mr-2 f-12 btn-eliminar f-300" @click="deleteTask">
                Eliminar
            </button>
        </template>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEliminarTarea.toggle()
        },
        deleteTask(){
            this.$refs.modalEliminarTarea.toggle()
            console.log('delete task');
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
